// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  language: '',
  urlFrame: 'api/framework/v1',
  url_portal: 'api/pc/v1',
  url_gfin: 'api/gfin/v1',
  url_ngf: 'api/ngf/v1',
  url_fin: 'api/fin/v1',
  version: 'DEV - v2.2.3',
  validatePilot: false,
  // Mingle
  app_identifier: '606469bb1e9d13e1fc907231',
  environment: 'DEV',
  server: 'https://dev-mingle.totvs.com.br',
  web: true,
  usage_metrics: true,
  gateway: true,
  ocr: false,
  crashr: false,
  push_notification: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
