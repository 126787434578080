import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { get, isNil, map } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private key = 'PCTOKEN';

  constructor(private router: Router) { }

  get authorization(): string {
    return `${this.getToken('token_type')} ${this.getToken('access_token')}`;
  }

  hasToken(): boolean {
    return !!this.getToken();
  }

  getToken(...value: Array<string>): any {
    let token: any;

    try {
      token = JSON.parse(sessionStorage.getItem(this.key));
    } catch (error) {}

    if (value.length > 0) {
      value.forEach(key => {
        token = get(token, key, null);
      });
    }
    return token;
  }

  setToken(value: any): void {
    sessionStorage.setItem(this.key, JSON.stringify(value));
  }

  addValue(key: string, value: any): void {
    const token = this.getToken();
    token[key] = value;
    this.setToken(token);
  }

  removeToken(): void {
    this.resetToken();
    this.removeRememberUser();
    if (!this.router.url.includes('login')){
      this.router.navigate(['/login']);
    }
  }

  resetToken(): void{
    sessionStorage.removeItem(this.key);
  }

  setRememberUser(userLogin: string, userPassword: string, userAlias: string): void {
    const user: any = { login: userLogin, password: userPassword, alias: userAlias };
    localStorage.setItem('userKeys', JSON.stringify(this.encryptValues64(JSON.stringify(user))));
  }

  getRememberUser(): any {
    const userEncrypted: string = JSON.parse(localStorage.getItem('userKeys'));
    return !isNil(userEncrypted) ? JSON.parse(this.decryptValues64(userEncrypted)) : '';
  }

  removeRememberUser(): void {
    localStorage.removeItem('userKeys');
  }

  private encryptValues64(value: string): string {
    const base64Value: string = btoa(value);
    const valuePartitions: Array<string> = base64Value.match(new RegExp('.{1,' + 4 + '}', 'g'));
    const valueEncrypted: string = map(valuePartitions, (part: string) => part + part.charAt(Math.floor(Math.random() * part.length))).join('');
    return valueEncrypted;
  }

  private decryptValues64(value: string): string {
    const valuePartitions: Array<string> = value.match(new RegExp('.{1,' + 5 + '}', 'g'));
    const base64Value: string = map(valuePartitions, (part: string) => part.slice(0, -1)).join('');
    const valueDecrypt: string = atob(base64Value);
    return valueDecrypt;
  }
}
