import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { TokenService } from '../token/token.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private tokenService: TokenService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const valid = this.tokenService.hasToken();

    if (valid) {
      this.router.navigate(['/pc']);
    }

    return !valid;
  }
}
