import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Configuration, MingleService
} from '@totvs/mingle';
import 'hammerjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingModule } from './shared/components/loading/loading.module';
import { PoI18nConfigModule } from './shared/po-ui/po-i18n.module';
import { HttpClientModule } from '@angular/common/http';
import { PoUiModule } from './shared/po-ui/po-ui.module';

export function configMingle(mingle: MingleService): () => Promise<any> {
  const config = new Configuration();
  config.app_identifier = environment.app_identifier;
  config.environment = environment.environment;
  config.server = environment.server;
  config.modules.web = environment.web;
  config.modules.usage_metrics = environment.usage_metrics;

  mingle.setConfiguration(config);
  return () : Promise<any> => mingle.init();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PoUiModule,
    LoadingModule,
    PoI18nConfigModule
  ],
  providers: [
    MingleService,
    {
      provide: APP_INITIALIZER,
      useFactory: configMingle,
      deps: [MingleService],
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: ''
    }
   ],
  bootstrap: [AppComponent],
})
export class AppModule { }
