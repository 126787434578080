import { isEmpty } from 'lodash';
import { CustomersService } from 'src/app/core/customers/customers.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../token/token.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private customersService: CustomersService,
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean |
  UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    const valid = this.tokenService.hasToken();
    const hasCustomers = !isEmpty(this.customersService.customers);
    if (!valid || !hasCustomers) {
      this.router.navigate(['/login']);
      this.tokenService.resetToken();
      return false
    } else {
      return true
    }
  }
}
