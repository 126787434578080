
import { LOCALE_ID, NgModule } from '@angular/core';
import { PoI18nConfig, PoI18nModule } from '@po-ui/ng-components';
import { literalsPtBR } from '../i18n/tfc_portal-PtBR';
import { literalsEnUS } from '../i18n/tfc_portal-EnUS';
import { literalsEsES } from '../i18n/tfc_portal-EsES';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt');
registerLocaleData(es, 'es');

const i18nConfig: PoI18nConfig = {
  default: {
    language: 'pt-BR',
    context: 'general',
    cache: true,
  },
  contexts: {
    general: {
      ['pt-BR']: literalsPtBR,
      ['En-US']: literalsEnUS,
      ['Es-ES']: literalsEsES,
    },
  },
};

@NgModule({
    imports: [PoI18nModule.config(i18nConfig)],
    exports: [PoI18nModule],
    providers: [
      {
        provide: LOCALE_ID,
        useValue: 'pt-BR'
      }
    ],
})
export class PoI18nConfigModule { }
