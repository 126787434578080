import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PoAccordionModule, PoButtonModule, PoChartModule, PoDisclaimerModule, PoFieldModule, PoModule, PoPageModule, PoTabsModule, PoTagModule } from '@po-ui/ng-components';
import { PoPageDynamicSearchModule, PoTemplatesModule } from '@po-ui/ng-templates';
import { I18nPipe } from '../pipes/i18n.pipe';
@NgModule({
  declarations: [I18nPipe],
  imports: [
    CommonModule,
    PoModule,
    PoTabsModule,
    PoDisclaimerModule,
    PoButtonModule,
    PoChartModule,
    ReactiveFormsModule,
    FormsModule,
    PoAccordionModule,
    PoPageDynamicSearchModule,
    PoTemplatesModule,
    PoFieldModule,
    PoPageModule,
    PoTagModule
  ],
  exports: [
    I18nPipe,
    CommonModule,
    PoModule,
    PoTabsModule,
    PoDisclaimerModule,
    PoButtonModule,
    PoChartModule,
    ReactiveFormsModule,
    FormsModule,
    PoAccordionModule,
    PoPageDynamicSearchModule,
    PoTemplatesModule,
    PoFieldModule,
    PoPageModule,
    PoTagModule
  ]
})
export class PoUiModule { }
