import { Injectable } from '@angular/core';
import { Customer, CustomerKey } from './customers';
import { stringToCNPJ } from 'src/app/shared/util/stringToCNPJ';
import { find, forEach, get, isEmpty, join, map, set, trim } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  public readonly keyCustomers = 'customerDetails';
  public readonly keyCustomersSelected = 'customerSelectedDetails';

  private customerDetails = {}
  private customerSelectedDetails = []

  constructor() { }

  get customers(): Array<Customer> {
    const customers:Array<Customer> = get(this.customerDetails, 'items');

    forEach(customers, customer => {
      set(customer, 'cnpj', stringToCNPJ(get(customer, 'cnpj')));
    });
    return customers;
  }

  get customersSelected(): Array<Customer> {
    let customersSelected = this.customerSelectedDetails;

    if (customersSelected?.length === 0) {
      customersSelected = this.customers;
    }

    return customersSelected;
  }

  getCustomer(branchId: string, customerId: string, storeId: string): Customer {
    return find(
      this.customers,
      (customer: Customer) =>
        isEmpty(trim(branchId)) || isEmpty(trim(get(customer, 'branchId'))) ?
        (get(customer, 'customerId') === customerId && get(customer, 'storeId') === storeId) :
        (get(customer, 'branchId') === branchId && get(customer, 'customerId') === customerId && get(customer, 'storeId') === storeId)
      );
  }

  setCustomersSelected(customers: Array<Customer>): void {
    this.customerSelectedDetails = customers;
  }

  getCustomersSelected(): Array<CustomerKey> {
    let formattedCustumers: Array<CustomerKey>;

    if (!isEmpty(this.customersSelected)) {
      formattedCustumers = map(this.customersSelected, customer => ({
        filial: customer['branchId'],
        codigo: customer['customerId'],
        loja: customer['storeId']
      }));
    }

    return  formattedCustumers;
  }

  setCustomerDetails(customers: any): void {
    this.customerDetails = customers;
    this.customerSelectedDetails = get(customers, 'items');
  }

  resetCustomers(): void{
    this.customerDetails = {};
    this.customerSelectedDetails = [];
  }

  getCustomersSelectedHeader(): String {
    let customers: string;

    if (!isEmpty(this.customersSelected)) {
      customers = join(map(this.customersSelected, customer =>
        `${customer['branchId']},${customer['customerId']},${customer['storeId']}`
      ), '|');
    }
    return  customers;
  }
}
