import { Injectable } from '@angular/core';
import { Observable, Subject } from '@totvs/mingle/node_modules/rxjs';
import { startWith } from '@totvs/mingle/node_modules/rxjs/operators';
import { LoadingType } from './loading-type';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingSubject = new Subject<LoadingType>();

  getLoading(): Observable<any> {
    return this.loadingSubject
      .asObservable()
      .pipe(startWith(LoadingType.LOADING));
  }

  start(): void {
    this.loadingSubject.next(LoadingType.LOADING);
  }

  stop(): void {
    this.loadingSubject.next(LoadingType.STOPPED);
  }
}
