import { Component, OnInit } from '@angular/core';
import { Observable } from '@totvs/mingle/node_modules/rxjs';
import { map } from '@totvs/mingle/node_modules/rxjs/operators';
import { I18nService } from 'src/app/shared/i18n/i18n.service';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  loading$: Observable<string>;

  constructor(private loadingService: LoadingService, public i18nService: I18nService) { }

  ngOnInit(): void {
    this.loading$ = this.loadingService
      .getLoading()
      .pipe(map(loadingType => loadingType.valueOf()));
  }
}
