import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subject, filter } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { LoadingService } from './shared/components/loading/loading.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private loading: LoadingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(event => event instanceof NavigationStart ||
                        event instanceof NavigationEnd ||
                        event instanceof NavigationError ||
                        event instanceof NavigationCancel
        )
      )
      .subscribe({
        next: (event: NavigationStart | NavigationEnd | NavigationError | NavigationCancel) => {
          if (event instanceof NavigationStart && event.url !== '/pc') {
            this.loading.start();
          } else {
            this.loading.stop();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
