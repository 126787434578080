import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { I18nService } from '../i18n/i18n.service';

@Pipe({
    name: 'i18n',
    pure: false
})
export class I18nPipe implements PipeTransform {
    asyncPipe: AsyncPipe;
    constructor(private i18nService: I18nService, private changeDetectorRef: ChangeDetectorRef) {
        this.asyncPipe = new AsyncPipe(changeDetectorRef);
    }
    transform(key: string): any {
        return this.asyncPipe.transform(this.i18nService.literals.pipe(map(literals => literals && key in literals ? literals[key] : key)));
    }
}