/* tslint:disable */
// TOTVS TypeScript translation resource
// Reference date : 20240322

export const literalsEnUS = {
  str0001: 'Homepage',
  str0002: 'Invoices',
  str0003: 'Bills',
  str0004: 'Home',
  str0005: 'Invoices',
  str0006: 'Selection of data',
  str0007: 'Exit',
  str0008: 'Customer Portal',
  str0009: 'Here you can view your dues, issue bank slips and barcodes, access your Invoices and much more!',
  str0010: 'From invoice',
  str0011: 'To invoice',
  str0012: 'Series',
  str0013: 'From Issue',
  str0014: 'To issue',
  str0015: 'Document',
  str0016: 'Issue',
  str0017: 'Details',
  str0018: 'Issuer',
  str0019: 'Issuer code',
  str0020: 'Invoice',
  str0021: 'Value',
  str0022: 'Recipient',
  str0023: 'EIN',
  str0024: 'Product',
  str0025: 'Quantity',
  str0026: 'Unitary Value',
  str0027: 'Prod.Value',
  str0028: '1 - Outflow',
  str0029: 'Invoice details',
  str0030: 'Invoice number',
  str0031: 'Access key',
  str0032: 'E-invoice number',
  str0033: 'More details',
  str0034: 'E-invoice data',
  str0035: 'Operation nature',
  str0036: 'Operation type',
  str0037: 'Issue date and time',
  str0038: 'IE(State Registration)',
  str0039: 'Name/Trade name',
  str0040: 'City',
  str0041: 'State(Federation Unit)',
  str0042: 'Products',
  str0043: 'Next 7 days',
  str0044: 'To expire',
  str0045: 'Expired',
  str0046: 'Pending bill',
  str0047: 'Bills in arrears',
  str0048: 'In arrears',
  str0049: 'Bill values',
  str0050: 'Copy field',
  str0051: 'Send bank slip by e-mail',
  str0052: 'Bill number',
  str0053: 'Pending',
  str0054: 'In arrears',
  str0055: 'Paid',
  str0056: 'From Bill',
  str0057: 'To Bill',
  str0058: 'From Expiration',
  str0059: 'To Expiration',
  str0060: 'Status',
  str0061: 'Bill',
  str0062: 'Dates',
  str0063: 'Installment',
  str0064: 'Type',
  str0065: 'Expiration',
  str0066: 'Balance',
  str0067: 'Barcodes',
  str0068: 'Barcodes copied!',
  str0069: 'Barcodes blank.',
  str0070: 'Cancel',
  str0071: 'Copy',
  str0072: 'Download successfull!',
  str0073: 'Installment Value',
  str0074: 'Bill Negotiation',
  str0075: 'No bill selected for this negotiation',
  str0076: 'Issue date',
  str0077: 'Real expiration',
  str0078: 'Penalty',
  str0079: 'Interests',
  str0080: 'Current Value',
  str0081: 'Negotiation Terms',
  str0082: 'Select number of installments',
  str0083: 'Number of Installments',
  str0084: 'Select Grace Days',
  str0085: 'Grace days',
  str0086: 'Calculate negotiation',
  str0087: 'Negotiation',
  str0088: 'Confirm negotiation',
  str0089: 'Data selection',
  str0090: 'Select at least one item.',
  str0091: 'Data successfully selected!',
  str0092: 'Branches',
  str0093: 'Customers',
  str0094: 'Confirmation',
  str0095: 'Back',
  str0096: 'Next',
  str0097: 'Finish',
  str0098: 'Branch',
  str0099: 'Code',
  str0100: 'Store',
  str0101: 'Name',
  str0102: 'Branch code',
  str0103: 'Branch name',
  str0104: 'State',
  str0105: 'Add user',
  str0106: 'Add access user',
  str0107: 'Add password',
  str0108: 'Add access password',
  str0109: 'Unable to collect currency configuration: "$". Standard decimal houses: 2.',
  str0110: 'Pending',
  str0111: 'Details not loaded. Please,try again.',
  str0112: 'Loading configurations...',
  str0113: 'Enter company identifier',
  str0114: 'Invalid user or password.',
  str0115: 'Details',
  str0116: 'Download bank slip',
  str0117: 'Bank slip download not available. Please contact support.',
  str0118: "Enter the Alias generated by Mingle.",
  str0119: 'Invoice number',
  str0120: 'Customer Portal released only from Release 12.1.33. Please update the environment.',
  str0121: 'Bills pending',
  str0122: 'Up to 7 days',
  str0123: '8 to 15 days',
  str0124: '16 to 30 days',
  str0125: 'Over 30 days',
  str0126: 'Bills due',
  str0127: 'Welcome',
  str0128: 'Loading...',
  str0129: 'Bank slip not available for download. Contact us.',
  str0130: 'News',
  str0131: 'Details',
  str0132: 'History',
  str0133: 'Postings',
  str0134: 'Return',
  str0135: 'Bill',
  str0136: 'Loading details',
  str0137: 'View',
  str0138: 'No customers are linked to this user and password, or they have no rights to use the Customer Portal. Please check routine Portal Users (FATA220) for details.',
  str0139: "Incorrect alias.",
  str0140: "User not found. (Mingle)",
  str0141: "Issues communicating with server.",
  str0142: "Unexpected error. Contact the system administrator.",
  str0143: "Warning:",
  str0144: "This change will be applied to all portals linked to this user",
  str0145: "Confirm",
  str0146: "Password successfully changed",
  str0147: "The password confirmation does not match",
  str0148: "The new password entered is already being used",
  str0149: "Change password",
  str0150: "Current password",
  str0151: "New password",
  str0152: "Confirm new password",
  str0153: "Server unavailable or no license is available to serve the requisition at this moment.",
  str0154: "Data to access invoice",
  str0155: "Company EIN (Contractor)",
  str0156: "URL not found for the city",
  str0157: "Error loading invoice data",
  str0158: "Dear customer, you will be redirected to the city's website.<br> To download the invoice, you need to fill in the information requested.",
  str0159: "Text copied to clipboard",
  str0160: "Keep user logged-in",
  str0161: "Reports",
  str0162: "No options available",
  str0163: "Download completed",
  str0164: "File downloaded successfully!",
  str0165: "Error downloading",
  str0166: "Could not download the file.",
  str0167: "Access Invoice",
  str0168: "No data to display.",
  str0169: "No attachments to be downloaded.",
  str0170: "City Registration",
  str0171: "Code",
  str0172: "Customer name",
  str0173: "Addition Date",
  str0174: "Order Number",
  str0175: "Subtotal",
  str0176: "Sales",
  str0177: "Closed",
  str0178: "Released",
  str0179: "Orders",
  str0180: "Customer Code",
  str0181: "Failed to load orders",
  str0182: "Activated",
  str0183: "New Order",
  str0184: "Order Note",
  str0185: "Search products",
  str0186: "Products selected",
  str0187: "Load more results",
  str0188: "Delete",
  str0189: "Add",
  str0190: "Product selected successfully!",
  str0191: "Product removed successfully!",
  str0192: "Price",
  str0193: "Total",
  str0194: "Start order",
  str0195: "Failed to register the order",
  str0196: "Save order",
  str0197: "Customer information",
  str0198: "Price Table",
  str0199: "Payment term",
  str0200: "Carrier Code",
  str0201: "Carrier",
  str0202: "Order information",
  str0203: "Order Note",
  str0204: "Order Total",
  str0205: "Item",
  str0206: "Description",
  str0207: "Unit Price",
  str0208: "Could not generate the PDF. Try again.",
  str0209: "EIN/SSN:",
  str0210: "Comments",
  str0211: "Order Items",
  str0212: "Order",
  str0213: "GENERATE PDF",
  str0214: "Customer Information",
  str0215: "Delivery and Payment",
  str0216: "Freight Type",
  str0217: "Order Details",
  str0218: "On behalf of third parties",
  str0219: "On behalf of the sender",
  str0220: "On behalf of the recipient",
  str0221: "No freight",
  str0222: "CIF",
  str0223: "FOB",
  str0224: "Not specified",
  str0225: "Insurance",
  str0226: "Freight",
  str0227: "Expenses",
  str0228: "Generating files, wait!",
  str0229: "Download DANFE e XML",
  str0230: "Electronic invoice not authorized",
  str0231: "Unable to download DANFE and XML",
  str0232: "Environment outdated for this option and/or electronic invoice server unavailable.",
  str0233: "For more details, access",
  str0234: "Topic 3",
  str0235: "Dicas de segurança!",
  str0236: "Evite uma senha que:",
  str0237: "seja usada em outras contas",
  str0238: "usa informações pessoais como seu nome, apelido ou nome de familiares",
  str0239: "use sequências muito óbvias como '1234' ou 'abcd' ou palavras óbvias como 'senha'",
  str0240: "Esqueceu sua senha?",
  str0241: "Preencha os campos abaixo para recuperar sua senha.",
  str0242: "Insira seu e-mail",
  str0243: "Recuperar senha",
  str0244: "E-mail de recuperação enviado. Por favor, verifique sua caixa de entrada",
  str0245: "Não foi possível concluir a recuperação de senha",
  str0246: "Ambiente desatualizado para esta opção",
  str0247: "(Topico 1 - LOGIN/Esqueci minha senha)",
  str0248: "Verifique o usuário e o e-mail informados ou tente novamente mais tarde."
};
