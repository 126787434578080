/* tslint:disable */
// TOTVS TypeScript translation resource
// Reference date : 20240322

export const literalsPtBR = {
  str0001: 'Página inicial',
  str0002: 'Notas fiscais',
  str0003: 'Títulos',
  str0004: 'Home',
  str0005: 'Notas',
  str0006: 'Seleção de Dados',
  str0007: 'Sair',
  str0008: 'Portal do Cliente',
  str0009: 'Aqui você tem como visualizar seus vencimentos, emitir boletos e códigos de barra, acessar suas NFs e muito mais!',
  str0010: 'NF de',
  str0011: 'NF até',
  str0012: 'Série',
  str0013: 'Emissão de',
  str0014: 'Emissão até',
  str0015: 'Documento',
  str0016: 'Emissão',
  str0017: 'Detalhes',
  str0018: 'Emitente',
  str0019: 'Código do emitente',
  str0020: 'Nota Fiscal',
  str0021: 'Valor',
  str0022: 'Destinatário',
  str0023: 'CNPJ',
  str0024: 'Produto',
  str0025: 'Quantidade',
  str0026: 'Valor unit.',
  str0027: 'Valor prod.',
  str0028: '1 - Saída',
  str0029: 'Detalhes da nota',
  str0030: 'Número da NF',
  str0031: 'Chave de acesso',
  str0032: 'Número NF-e',
  str0033: 'Mais detalhes',
  str0034: 'Dados da NF-e',
  str0035: 'Natureza da operação',
  str0036: 'Tipo de operação',
  str0037: 'Data e hora de emissão',
  str0038: 'IE (Inscrição Estadual)',
  str0039: 'Nome/Razão social',
  str0040: 'Município',
  str0041: 'UF (Unidade da Federação)',
  str0042: 'Produtos',
  str0043: 'Próximos 7 dias',
  str0044: 'À vencer',
  str0045: 'Vencido',
  str0046: 'Títulos em aberto',
  str0047: 'Títulos em atraso',
  str0048: 'Em atraso',
  str0049: 'Valores dos títulos',
  str0050: 'Copiar linha digitável',
  str0051: 'Enviar boleto por e-mail',
  str0052: 'Número do Título',
  str0053: 'Aberto',
  str0054: 'Atrasado',
  str0055: 'Pago',
  str0056: 'Título de',
  str0057: 'Título até',
  str0058: 'Vencimento de',
  str0059: 'Vencimento até',
  str0060: 'Status',
  str0061: 'Título',
  str0062: 'Datas',
  str0063: 'Parcela',
  str0064: 'Tipo',
  str0065: 'Vencimento',
  str0066: 'Saldo',
  str0067: 'Codigo de barras',
  str0068: 'Código de barras copiado!',
  str0069: 'Código de barras em branco.',
  str0070: 'Cancelar',
  str0071: 'Copiar',
  str0072: 'Download realizado com sucesso!',
  str0073: 'Valor da Parcela',
  str0074: 'Negociação de Título',
  str0075: 'Nenhum Título selecionado para negociação',
  str0076: 'Data de Emissão',
  str0077: 'Vencimento real',
  str0078: 'Multa',
  str0079: 'Juros',
  str0080: 'Valor Atual',
  str0081: 'Condições de Negociação',
  str0082: 'Selecione o Número de Parcelas',
  str0083: 'Número de Parcelas',
  str0084: 'Selecione os Dias de Carência',
  str0085: 'Dias de Carência',
  str0086: 'Calcular Negociação',
  str0087: 'Negociação',
  str0088: 'Confirmar Negociação',
  str0089: 'Seleção de Dados',
  str0090: 'Selecione pelo menos um item.',
  str0091: 'A seleção de dados foi realizada com sucesso!',
  str0092: 'Filiais',
  str0093: 'Clientes',
  str0094: 'Confirmação',
  str0095: 'Voltar',
  str0096: 'Avançar',
  str0097: 'Concluir',
  str0098: 'Filial',
  str0099: 'Código',
  str0100: 'Loja',
  str0101: 'Nome',
  str0102: 'Código da filial',
  str0103: 'Nome da filial',
  str0104: 'Estado',
  str0105: 'Insira seu usuário',
  str0106: 'Insira seu usuário de acesso',
  str0107: 'Insira sua senha',
  str0108: 'Insira sua senha de acesso',
  str0109: 'Não foi possível coletar as configurações da moeda. Símbolo padrão: "$". Casas decimais padrão: 2.',
  str0110: 'Em aberto',
  str0111: 'Os detalhes não foram carregados. Por favor, tente novamente.',
  str0112: 'Carregando configurações...',
  str0113: 'Insira o identificador da empresa',
  str0114: 'Usuário ou senha inválidos.',
  str0115: 'Detalhes',
  str0116: 'Baixar boleto',
  str0117: 'Download do boleto não está disponível. Favor entrar em contato.',
  str0118: "Insira o Alias gerado pelo Mingle.",
  str0119: 'Número da nota',
  str0120: 'Portal do Cliente liberado somente à partir do Release 12.1.33. Favor atualizar o ambiente.',
  str0121: 'Títulos em aberto',
  str0122: 'Até 7 dias',
  str0123: '8 a 15 dias',
  str0124: '16 a 30 dias',
  str0125: 'Maior que 30 dias',
  str0126: 'Títulos à vencer',
  str0127: 'Bem-vindo(a)',
  str0128: 'Carregando...',
  str0129: 'Boleto não disponível para download. Favor entrar em contato.',
  str0130: 'Notícias',
  str0131: 'Detalhes',
  str0132: 'Históricos',
  str0133: 'Baixas',
  str0134: 'Voltar',
  str0135: 'Título',
  str0136: 'Carregando detalhes',
  str0137: 'Visualizar',
  str0138: 'Não existem clientes vinculados à este usuário e senha, ou o mesmo não possui direitos para utilizar o Portal do Cliente. Favor verificar detalhes na rotina Usuários Portal (FATA220).',
  str0139: "Alias informado está incorreto.",
  str0140: "Usuário informado não foi encontrado. (Mingle)",
  str0141: "Problemas de comunicação com servidor.",
  str0142: "Ocorreu algum erro inesperado. Contate o administrador do sistema.",
  str0143: "Atenção: ",
  str0144: "Essa alteração será aplicada em todos os portais vinculados a esse usuário",
  str0145: "Confirmar",
  str0146: "Senha alterada com sucesso",
  str0147: "A confirmação de senha não confere",
  str0148: "A nova senha informada já está em uso",
  str0149: "Alterar senha",
  str0150: "Senha atual",
  str0151: "Nova senha",
  str0152: "Confirme a nova senha",
  str0153: "Servidor fora do ar ou não existe licença disponível para atender a requisição nesse momento.",
  str0154: "Dados para acesso da nota fiscal",
  str0155: "CNPJ Empresa (Tomador)",
  str0156: "URL não encontrada para o município",
  str0157: "Erro ao carregar os dados da nota",
  str0158: "Caro cliente, você será redirecionado ao site da prefeitura.<br> Para fazer download da nota fiscal, é necessário preencher as informações solicitadas.",
  str0159: "Texto copiado para área de transferência",
  str0160: "Manter usuário logado",
  str0161: "Relatórios",
  str0162: "Não existem opções disponíveis",
  str0163: "Download concluído",
  str0164: "Download do arquivo realizado com sucesso!",
  str0165: "Erro ao efetuar download",
  str0166: "Não foi possível realizar o download do arquivo.",
  str0167: "Acessar Nota Fiscal",
  str0168: "Não há dados a serem exibidos.",
  str0169: "Não há anexos a serem baixados.",
  str0170: "Inscrição Municipal",
  str0171: "Código",
  str0172: "Nome do cliente",
  str0173: "Data de inclusão",
  str0174: "Número do pedido",
  str0175: "Sub-total",
  str0176: "Vendas",
  str0177: "Encerrado",
  str0178: "Liberado",
  str0179: "Pedidos",
  str0180: "Código do cliente",
  str0181: "Falha ao carregar pedidos",
  str0182: "Ativado",
  str0183: "Novo pedido",
  str0184: "Comentário do pedido",
  str0185: "Pesquisar produtos",
  str0186: "Produtos selecionados",
  str0187: "Carregar mais resultados",
  str0188: "Remover",
  str0189: "Adicionar",
  str0190: "Produto selecionado com sucesso!",
  str0191: "Produto removido com sucesso!",
  str0192: "Preço",
  str0193: "Total",
  str0194: "Iniciar pedido",
  str0195: "Falha ao registrar o pedido",
  str0196: "Salvar pedido",
  str0197: "Informações do cliente",
  str0198: "Tabela de preço",
  str0199: "Condição de pagamento",
  str0200: "Código da transportadora",
  str0201: "Transportadora",
  str0202: "Informações do pedido",
  str0203: "Comentário do pedido",
  str0204: "Total do pedido",
  str0205: "Item",
  str0206: "Descrição",
  str0207: "Preço Unitário",
  str0208: "Não foi possível gerar o PDF. Tente novamente.",
  str0209: "CNPJ/CPF: ",
  str0210: "Comentários",
  str0211: "Items do Pedido",
  str0212: "Pedido",
  str0213: "GERAR PDF",
  str0214: "Informações do Cliente",
  str0215: "Entrega e Pagamento",
  str0216: "Tipo de Frete",
  str0217: "Detalhes do Pedido",
  str0218: "Por conta terceiros",
  str0219: "Por conta remetente",
  str0220: "Por conta destinatário",
  str0221: "Sem frete",
  str0222: "CIF",
  str0223: "FOB",
  str0224: "Não especificado",
  str0225: "Seguro",
  str0226: "Frete",
  str0227: "Despesas",
  str0228: "Gerando arquivos, por favor aguarde!",
  str0229: "Baixar DANFE e XML",
  str0230: "Nota fiscal eletrônica não autorizada",
  str0231: "Não foi possivel baixar a DANFE e XML",
  str0232: "Ambiente desatulizado para essa opção e/ou servidor de notas fiscais eletrônicas indisponível.",
  str0233: "Para mais detalhes acesse",
  str0234: "Tópico 3",
  str0235: "Dicas de segurança!",
  str0236: "Evite uma senha que:",
  str0237: "seja usada em outras contas",
  str0238: "usa informações pessoais como seu nome, apelido ou nome de familiares",
  str0239: "use sequências muito óbvias como '1234' ou 'abcd' ou palavras óbvias como 'senha'",
  str0240: "Esqueceu sua senha?",
  str0241: "Preencha os campos abaixo para recuperar sua senha.",
  str0242: "Insira seu e-mail",
  str0243: "Recuperar senha",
  str0244: "E-mail de recuperação enviado. Por favor, verifique sua caixa de entrada",
  str0245: "Não foi possível concluir a recuperação de senha",
  str0246: "Ambiente desatualizado para esta opção",
  str0247: "(Topico 1 - LOGIN/Esqueci minha senha)",
  str0248: "Verifique o usuário e o e-mail informados ou tente novamente mais tarde."
};
