/* tslint:disable */
// TOTVS TypeScript translation resource
// Reference date : 20240322

export const literalsEsES = {
  str0001: 'Página inicial',
  str0002: 'Facturas',
  str0003: 'Títulos',
  str0004: 'Home',
  str0005: 'Facturas',
  str0006: 'Selección de datos',
  str0007: 'Salir',
  str0008: 'Portal del Cliente',
  str0009: 'Aquí puede visualizar sus vencimientos, emitir boletas y códigos de barra, acceder a sus facturas y mucho más.',
  str0010: 'De Factura',
  str0011: 'A Factura',
  str0012: 'Serie',
  str0013: 'De Emisión',
  str0014: 'A Emisión',
  str0015: 'Documento',
  str0016: 'Emisión',
  str0017: 'Detalles',
  str0018: 'Emisor',
  str0019: 'Código del emisor',
  str0020: 'Factura',
  str0021: 'Valor',
  str0022: 'Destinatario',
  str0023: 'RCPJ',
  str0024: 'Producto',
  str0025: 'Cantidad',
  str0026: 'Valor Unit.',
  str0027: 'Valor Prod.',
  str0028: '1 - Salida',
  str0029: 'Detalles de la factura',
  str0030: 'Número de la factura',
  str0031: 'Clave de acceso',
  str0032: 'Número de la e-Fact',
  str0033: 'Más detalles',
  str0034: 'Datos de la e-Fact',
  str0035: 'Modalidad de la operación',
  str0036: 'Tipo de operación',
  str0037: 'Fecha y hora de emisión',
  str0038: 'IE(Inscripción estatal)',
  str0039: 'Nombre/Razón social',
  str0040: 'Municipio',
  str0041: 'Estado/Prov/Reg (Unidad de la Federación)',
  str0042: 'Productos',
  str0043: 'Próximos 7 días',
  str0044: 'Por vencer',
  str0045: 'Vencido',
  str0046: 'Titulo pendiente',
  str0047: 'Títulos atrasados',
  str0048: 'Atrasado',
  str0049: 'Valor de los títulos',
  str0050: 'Copiar línea digitable',
  str0051: 'Enviar boleta por e-mail',
  str0052: 'Numero del título',
  str0053: 'Pendiente',
  str0054: 'Atrasado',
  str0055: 'Pagado',
  str0056: 'De Título',
  str0057: 'A Título',
  str0058: 'De Vencimiento',
  str0059: 'A Vencimiento',
  str0060: 'Estatus',
  str0061: 'Título',
  str0062: 'Fechas',
  str0063: 'Cuota',
  str0064: 'Tipo',
  str0065: 'Vencimiento',
  str0066: 'Saldo',
  str0067: 'Código de barras',
  str0068: '¡Código de barras copiado!',
  str0069: 'Código de barras en blanco.',
  str0070: 'Anular',
  str0071: 'Copiar',
  str0072: '¡Download realizado con éxito!',
  str0073: 'Valor de la cuota',
  str0074: 'Negociación de título',
  str0075: 'Ningún título seleccionado para negociación',
  str0076: 'Fecha de emisión',
  str0077: 'Vencimiento real',
  str0078: 'Multa',
  str0079: 'Interés',
  str0080: 'Valor actual',
  str0081: 'Condiciones de negociación',
  str0082: 'Seleccione el número de cuotas',
  str0083: 'Número de cuotas',
  str0084: 'Seleccione los Días de carencia',
  str0085: 'Días de carencia',
  str0086: 'Calcular negociación',
  str0087: 'Negociación',
  str0088: 'Confirmar negociación',
  str0089: 'Selección de datos',
  str0090: 'Seleccione por lo menos un ítem.',
  str0091: '¡La selección de datos se realizó con éxito!',
  str0092: 'Sucursales',
  str0093: 'Clientes',
  str0094: 'Confirmación',
  str0095: 'Volver',
  str0096: 'Avanzar',
  str0097: 'Finalizar',
  str0098: 'Sucursal',
  str0099: 'Código',
  str0100: 'Tienda',
  str0101: 'Nombre',
  str0102: 'Código de la sucursal',
  str0103: 'Nombre de la sucursal',
  str0104: 'Est/Prov/Reg',
  str0105: 'Incluya su usuario',
  str0106: 'Incluya su usuario de acceso',
  str0107: 'Incluya su contraseña',
  str0108: 'Incluya su contraseña de acceso',
  str0109: 'No fue posible recolectar las configuraciones de la moneda. Símbolo estándar: "$". Decimales estándar: 2.',
  str0110: 'Pendiente',
  str0111: 'Los detalles no se activaron. Por favor, intente nuevamente.',
  str0112: 'Activando configuraciones',
  str0113: 'Incluya el identificador de la empresa',
  str0114: 'Usuario o contraseña no válidos.',
  str0115: 'Detalles',
  str0116: 'Bajar boleta',
  str0117: 'Download de la boleta no está disponible. Por favor, entre en contacto.',
  str0118: "Incluya el Alias generado por el Mingle.",
  str0119: 'Número de la factura',
  str0120: 'Portal del cliente liberado solamente a partir del Release 12.1.33. Por favor, actualizar el entorno.',
  str0121: 'Títulos pendientes',
  str0122: 'Hasta 7 días',
  str0123: '8 a 15 días',
  str0124: '16 a 30 días',
  str0125: 'Mayor que 30 días',
  str0126: 'Títulos por vencer',
  str0127: '¡Bienvenido(a)!',
  str0128: 'Cargando...',
  str0129: 'Boleta no disponible para download. Por favor, entre en contacto.',
  str0130: 'Noticias',
  str0131: 'Detalles',
  str0132: 'Historiales',
  str0133: 'Bajas',
  str0134: 'Volver',
  str0135: 'Título',
  str0136: 'Cargando detalles',
  str0137: 'Visualizar',
  str0138: 'No existen clientes vinculados a este usuario y contraseña, o este no tiene derechos para utilizar el Portal del cliente. Por favor, verifique detalles en la rutina Usuarios portal (FATA220).',
  str0139: "Alias informado está incorrecto.",
  str0140: "No se encontró usuario informado. (Mingle)",
  str0141: "Problemas de comunicación con servidor.",
  str0142: "Ocurrió algún error inesperado. Entre en contacto con el administrador del sistema.",
  str0143: "Atención:",
  str0144: "Esta modificación se aplicará en todos los portales vinculados a este usuario",
  str0145: "Confirmar",
  str0146: "¡Contraseña modificada con éxito!",
  str0147: "La confirmación de contraseña no coincide",
  str0148: "La nueva contraseña informada ya está en uso",
  str0149: "Modificar contraseña",
  str0150: "Contraseña actual",
  str0151: "Nueva contraseña",
  str0152: "Confirme la nueva contraseña",
  str0153: "Servidor sin conexión, o no existe licencia disponible para cumplir con este requerimiento en este momento",
  str0154: "Datos para acceso de la factura",
  str0155: "RCPJ Empresa (Tomador)",
  str0156: "URL no encontrada para el municipio",
  str0157: "Error al cargar los datos de la factura",
  str0158: "Estimado cliente, usted será orientado a dirigirse al sitio del municipio.<br> Para descargar la factura, es necesario completar las informaciones solicitadas.",
  str0159: "Texto copiado al área de transferencia",
  str0160: "Mantiene usuario conectado",
  str0161: "Informes",
  str0162: "No existen opciones disponibles",
  str0163: "Descarga finalizada",
  str0164: "¡Descarga del archivo realizada con éxito!",
  str0165: "Error al realizar la descarga",
  str0166: "No se pudo realizar la descarga del archivo.",
  str0167: "Acceda a la factura",
  str0168: "No hay datos por mostrarse.",
  str0169: "No hay datos para eliminar.",
  str0170: "Inscripción municipal",
  str0171: "Código",
  str0172: "Nombre del cliente",
  str0173: "Fecha de inclusión",
  str0174: "Numero del pedido",
  str0175: "Subtotal",
  str0176: "Ventas",
  str0177: "Finalizado",
  str0178: "Liberado",
  str0179: "Pedidos",
  str0180: "Código del cliente",
  str0181: "Falla al cargar pedidos",
  str0182: "Activado",
  str0183: "Nuevo pedido",
  str0184: "Comentario del pedido",
  str0185: "Buscar productos",
  str0186: "Productos seleccionados",
  str0187: "Cargar más resultados",
  str0188: "Eliminar",
  str0189: "Agregar",
  str0190: "¡Producto seleccionado con éxito!",
  str0191: "¡Producto retirado con éxito!",
  str0192: "Precio",
  str0193: "Total",
  str0194: "Iniciar pedido",
  str0195: "Falla al registrar el pedido",
  str0196: "Grabar pedido",
  str0197: "Información del cliente",
  str0198: "Lista de precios",
  str0199: "Condición de pago",
  str0200: "Código de la empresa de transportes",
  str0201: "Empresa de transportes",
  str0202: "Información del pedido",
  str0203: "Comentario del pedido",
  str0204: "Total del pedido",
  str0205: "Ítem",
  str0206: "Descripción",
  str0207: "Precio unitario",
  str0208: "No fue posible generar el PDF. Intente nuevamente.",
  str0209: "RCPJ/RCPF:",
  str0210: "Comentarios",
  str0211: "Ítems del pedido",
  str0212: "Pedido",
  str0213: "GENERAR PDF",
  str0214: "Información del cliente",
  str0215: "Entrega y pago",
  str0216: "Tipo de flete",
  str0217: "Detalles del pedido",
  str0218: "Por cuenta de terceros",
  str0219: "Por cuenta remitente",
  str0220: "Por cuenta destinatario",
  str0221: "Sin flete",
  str0222: "CIF",
  str0223: "FOB",
  str0224: "No especificado",
  str0225: "Seguro",
  str0226: "Flete",
  str0227: "Gastos",
  str0228: "Generando archivos, ¡por favor, espere!",
  str0229: "Bajar DANFE y XML",
  str0230: "Factura electrónica no autorizada",
  str0231: "No fue posible bajar la DANFE y el XML",
  str0232: "Entorno desactualizado para esta opción y/o el servidor de facturas electrónicas indisponible.",
  str0233: "Para más detalles acceda a",
  str0234: "Tópico 3",
  str0235: "Dicas de segurança!",
  str0236: "Evite uma senha que:",
  str0237: "seja usada em outras contas",
  str0238: "usa informações pessoais como seu nome, apelido ou nome de familiares",
  str0239: "use sequências muito óbvias como '1234' ou 'abcd' ou palavras óbvias como 'senha'",
  str0240: "Esqueceu sua senha?",
  str0241: "Preencha os campos abaixo para recuperar sua senha.",
  str0242: "Insira seu e-mail",
  str0243: "Recuperar senha",
  str0244: "E-mail de recuperação enviado. Por favor, verifique sua caixa de entrada",
  str0245: "Não foi possível concluir a recuperação de senha",
  str0246: "Ambiente desatualizado para esta opção",
  str0247: "(Topico 1 - LOGIN/Esqueci minha senha)",
  str0248: "Verifique o usuário e o e-mail informados ou tente novamente mais tarde."
};
