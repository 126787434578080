import { Injectable } from '@angular/core';
import { PoI18nService } from '@po-ui/ng-components';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  language: string;
  private _literals: BehaviorSubject<{any: string}> = new BehaviorSubject<{any: string}>(null);

  constructor(private poI18nService: PoI18nService) {
    this.setLanguage(this.poI18nService.getLanguage() || navigator.language);
  }

  get literals(): Observable<{any: string}> {
    return this._literals.asObservable();
  }
  get literalsValues(): {any: string} {
    return this._literals.value;
  }

  getLiteralByKey(key: string): string {
    return key in this.literalsValues ? this.literalsValues[key] : key;
  }

  setLanguage(language: string): void {
    const fixLanguage: string = !!language && language.length > 0 ? language.substr(0, 2).toLowerCase() : '';
    switch (fixLanguage) {
      case 'en': {
        language = 'en-US';
        environment.language = language.toLowerCase();
        break;
      }
      case 'pt': {
        language = 'pt-BR';
        environment.language = language.toLowerCase();
        break;
      }
      case 'es': {
        language = 'es-ES';
        environment.language = language.toLowerCase();
        break;
      }
      default: {
        language = 'pt-BR';
        environment.language = language.toLowerCase();
        break;
      }
    }
    if (this.language !== language) {
      this.language = language;
      this.poI18nService.setLanguage(this.language);
      this.poI18nService.getLiterals({ language: this.language })
        .pipe(first())
        .subscribe({
          next: (literals: {any: string}) => {
            this._literals.next(literals);
          }
        });
    }
  }
}
